@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import './assets/scss/custom.scss';

// Import Light mode
@import './assets/scss/bootstrap.scss';
@import './assets/scss/style.scss';

// Import Dark RTL mode
// @import './assets/scss/bootstrap-dark.scss';
// @import './assets/scss/style-dark.scss';
// @import './assets/scss/style-rtl.scss';

// Import RTL mode
// @import './assets/scss/bootstrap.scss';
// @import './assets/scss/style.scss';
// @import './assets/scss/style-rtl.scss';

// Import Dark Mode
// @import './assets/scss/bootstrap-dark.scss';
// @import './assets/scss/style-dark.scss';

//features scss
@import "./assets/scss/features"; 

// drawer
.react-drawer-drawer {
  z-index: 9999;
  background: #fff !important;
  width: 350px !important;
}

.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}

.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  // transition: all 0.4s;
}

.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  // transition: all 0.4s;
}

.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.ReactModal__Overlay {
  z-index: 5000 !important;
}

.react-drawer-overlay {
  z-index: 1050;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #0a58ca;
  background-color: transparent;
}

.custom-badge-color {
  &.bg-soft-primary {
    color: $primary;
    background-color: rgba($primary, 0.1) !important;
  }
}

.carousel-overlay {
  .carousel-item {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: $overlay;
    }
  }
}

.wicon a i {
  align-items: center;
  display: flex;
}

#style-switcher {
  transition: all .4s;
  background: none repeat scroll 0 0 $white;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  left: -189px;
  position: fixed;
  top: 11%;
  width: 189px;
  z-index: 9999;
  padding: 10px 5px;

  .bottom a.settings {
    background: none repeat scroll 0 0 $white;
    height: 41px;
    position: absolute;
    right: -41px;
    top: 100px;
    width: 40px;
    padding: 3px;
    border-radius: 0 30px 30px 0;
  }
}

.cookie-popup.alert {
  .btn-close {
    top: 17px;
    right: 17px;
  }
}

.activeNavbarElement-light , .activeNavbarElement-dark{
  color: white !important;
  border-bottom: 2px solid white;
  padding-bottom: 3px !important;
}

@media (max-width:991px) {
  .activeNavbarElement-dark , .activeNavbarElement-dark{
    color: #001c28 !important;
    border-bottom: 2px solid white;
    padding-bottom: 3px !important;
  }
}

.custom-img-slider-m17{
  width: 100%;
  height: 300px;
  @media (max-width:991px) {
    height: 350px;
  }
  @media (max-width:500px) {
    height: 200px;
  }
  @media (max-width:325px) {
    height: 150px;
  }
}

.custom-categories-m17{
  @media (max-width:1050px) {
    font-size: 11px !important;
    padding: 0px 12px !important;
    margin: 4px !important;
    line-height: 28px !important;
  }
  @media (max-width:880px) {
    font-size: 9px !important;
    padding: 0px 10px !important;
    margin: 4px 5px !important;
    line-height: 22px !important;
  }
  @media (max-width:760px) {
    font-size: 8px !important;
    padding: 0px 7px !important;
    margin: 3px 5px !important;
    line-height: 18px !important;
  }
  @media (max-width:660px) {
    font-size: 12px !important;
    padding: 0px 10px !important;
    margin: 4px 8px !important;
    line-height: 26px !important;
  }
  @media (max-width:540px) {
    font-size: 9px !important;
    padding: 0px 8px !important;
    margin: 4px 6px !important;
    line-height: 22px !important;
  }
  @media (max-width:420px) {
    font-size: 11px !important;
    padding: 0px 8px !important;
    width: 90% !important;
    margin: 4px auto !important;
    line-height: 22px !important;
  }
}
.custom-categories-container-m17{
  @media (max-width:420px) {
    text-align: center;
  }
}

.bg-custom-m17{
  background-color: #ada262 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a{
  color: white !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a{
  color: #ada262 !important;
}
#topnav.nav-sticky{
  background-color: #001c28 !important;
}
@media (max-width:991px) {
  #topnav.nav-sticky .navigation-menu.nav-light > li > a{
    color: #001c28 !important;
  }
}
// .howToWinHover{
//   transition-duration: 1s;
//   &:hover{
//     border-radius: 10px;
//     cursor: default;
//     background-color: #001c28;
//       h4 , p{
//         transition-duration: 0.6s;
//         color: white !important;
//       }
//   }
// }
.nav-sticky{
  .custom_user_login{
    border: 2px solid white;
  }
}
.custom_user_login{
  background-color: #001c28;
  border-radius: 10px;
  color: white;
  outline: none;
  border: 2px solid #001c28;
  padding: 5px 10px;
  font-size: 1.1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  transition-duration: 1s;
  font-weight: bold;
  &:hover{
    color: #001c28;
    background-color: white;
  }
  @media (max-width:992px) {
    color: #001c28;
    background-color: white;
  }
}
.custom_about_card{
  border-radius: 15px ;
  padding: 20px;
  box-shadow: 0px 0px 10px 1px #001c282a;
}
.cursor-pointer{
  cursor: pointer;
}
.avatar-content{
  background-color: #ada262;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 0.8rem;
  font-weight: bold;
}
.dropdown-user {
  line-height: 1 !important; 
  .dropdown-menu {
    left: auto !important;
    right: 0;
    margin-top: 10px;
    i,
    svg {
      height: 16px;
      width: 16px;
      font-size: 16px;
      vertical-align: top;
    }
  }
}
.dropdown-user-link {
  display: flex;
  align-items: center;

  .user-name {
    display: inline-block;
    color:white;
  }
  .user-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: left;
    margin-right: $spacer - 0.2;
  }
}
.custom-select-m17{
  .ant-select-selector{
    border: 1px solid #8E9099 !important;
    height: 36px !important;
  }
}
.custom_orders_holdings{
  @media (min-width:992px) {
    flex-wrap: nowrap;
  }
}
.custom_order_title{
  @media (max-width:767px) {
    font-size: 0.9rem !important;
  }
  @media (max-width:500px) {
    font-size: 0.8rem !important;
  }
  @media (max-width:335px) {
    font-size: 0.6rem !important;
    padding: 5px 10px !important;
  }
}
.navigation-menu{
  @media (max-width:991px) {
    .activeNavbarElement-light{
      color: #001c28 !important;
    }
  }
}

.page_action_error{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.page_action_error svg{
  cursor: pointer;
  animation: rotate 0.8s linear infinite;
  animation-play-state: paused;
}
.playAnimation{
  animation-play-state: running !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom_modal_m17{
  .btn-close{
    // background-color: red !important;
    border: 1px solid #001c28;
    border-radius: 5px;
    width: 20px;
    height: 20px;
    color: #001c28;
    position: relative;
    &::after{
      content: 'X';
      position: absolute;
      left: 50%;
      width: 15px;
      height: 15px;
      font-size: 1.5rem;
      top: 50%;
      transform: translate(-50%,10%);
    }
  }
}
.mb-150{
  margin-bottom: 150px;
}
@media (max-width:500px) {
  .company_logo_m17{
    display: none;
  }
}

.custom_container_sponser_m17{
  margin: 0px auto !important;
  @media (max-width:767px) {
    width: 100%;
    max-width: 100%;
    .custom_container_col_sponser_m17{
      justify-content: space-around !important;
    }
    h5{
      font-size: 1.1rem !important;
    }
  }
  @media (max-width:575px) {
    .custom_container_col_sponser_m17{
      flex-wrap: wrap;
      justify-content: center!important;
    }
    h5{
      font-size: 1.1rem !important;
    }
  }
  @media (max-width:380px) {
  .custom_container_col_sponser_m17_card{
    width: 100%;
  }
  }
}

.title-heading{
  .heading{
    @media (max-width:1200px) {
      font-size: 50px !important;
    }
    @media (max-width:767px) {
      font-size: 40px !important;
    }
    @media (max-width:520px) {
      font-size: 30px !important;
    }
    @media (max-width:400px) {
      font-size: 18px !important;
    }
  }
  .para-desc{
    @media (max-width:1200px) {
      font-size: 20px !important;
    }
    @media (max-width:767px) {
      font-size: 16px !important;
    }
    @media (max-width:520px) {
      font-size: 14px !important;
    }
    @media (max-width:400px) {
      font-size: 12px !important;
    }
  }
}
@media (max-width:992px) {
  .commenter{
    h1{
      font-size: calc(1rem + 1.65vw);
    }
    h6{
      font-size: 0.9rem;
    }
  }
}
@media (max-width:767px) {
  .commenter{
    h1{
      font-size: calc(1rem + 1.2vw);
    }
    h6{
      font-size: 0.8rem;
    }
  }
  .custom_m17_badge{
    font-size: 1.3rem !important;
    width: 150px !important;
  }
}

#root{
  overflow-x: hidden;
}

.custom_account_card{
  @media (max-width:430px) {
    font-size: 0.7rem;
  }
  @media (max-width:345px) {
    font-size: 0.6rem;
    padding: 10px !important;
  }
}
.ad_m17{
  position: absolute;
  bottom: 0%;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
  // padding-bottom: 10px;
}
.celebration_m17{
  position: absolute;
  z-index: 10;
  height: 100%;
  margin-top: 120px;
}

.celebration_m17_h1 {
  position: absolute;
  margin-top: 30vh;
  width: 100%;
  text-align: center;
  font-size: 333%;
  font-family: sans-serif;
  color: white !important;
  opacity: 1;
  letter-spacing: 1px;
}

.celebration_m17_canvas {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
}

