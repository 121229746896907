// @import '../../../base/bootstrap-extended/include';
// @import '../../../base/components/include';

.react-dataTable {
  border-radius: 0;
  .rdt_TableHead {
    .rdt_TableHeadRow {
      background-color: transparent;
      border-top: 1px solid;
      border-color: #edebf2;
      min-height: 0;
      height: 38px;

      .rdt_TableCol {
        padding: 0.4rem;
        .rdt_TableCol_Sortable {
          color: #6e6b7b;
          font-weight: bold;
          font-style: 0.857rem;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          &:hover {
            opacity: 1;
          }
          span {
            margin-left: 0.35rem;
          }
        }
        &:first-child {
          .form-check {
            transform: translateX(-10px);
          }
        }
      }
    }
  }

  .rdt_TableBody {
    .rdt_TableRow {
      color: #6e6b7b;
      .rdt_TableCell {
        height: 53px;
        padding: 0.72rem 1.5rem;

        .column-action {
          svg {
            stroke: #001c28;
          }

          .dropdown-item:hover {
            svg {
              stroke: #001c28;
            }
          }

          .dropdown-item:active {
            svg {
              stroke: #001c28;
            }
          }
        }
      }
    }
  }

  .react-paginate {
    &.separated-pagination {
      .page-item:first-child .page-link,
      .page-item:last-child .page-link {
        border-radius: 50%;
      }
    }
  }

  &.roles-table {
    .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:first-child .form-check {
      transform: translateX(0);
    }
    .rdt_TableBody .rdt_TableRow .rdt_TableCell:first-child .form-check {
      transform: translateX(10px);
    }
  }

  &.react-dataTable-selectable-rows {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol:not(:first-child) {
          padding-left: 0.2rem;
        }
      }
    }
  }
}

.permission-header {
  @media (max-width: 740px) {
    .add-permission {
      margin-top: 1rem !important;
    }
  }
}

.rdt_Pagination {
  select {
    min-width: 30px;
    &:focus,
    &:active {
      outline: 0;
    }
  }
}

.dataTable-filter {
  max-width: 220px;
}

.dataTable-select {
  width: 5rem;
  &:not([multiple='multiple']) {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    background-position: calc(100% - 12px) 10px, calc(100% - 20px) 12px, 100% 0;
    background-size: 10px 10px, 10px 10px;
  }
}

.dark-layout {
  .app-user-list {
    header {
      background-color: green;
    }
  }

  .react-dataTable {
    .hlidmt {
      background-color: green;
    }
    .khKjDK {
      background-color: green;
      color: brown;
    }

    .rdt_Table {
      background: black;
      color: brown;

      & > div:first-child:not(.rdt_TableHead):not(.rdt_TableBody) {
        color: brown;
        background-color: green;
      }
    }
    .rdt_TableHead {
      .rdt_TableHeadRow {
        background-color: black;
        border-color: #eee;
        .rdt_TableCol {
          color: brown;
          div[role='button'] {
            color: brown;
          }
        }
        .rdt_TableCol_Sortable {
          color: yellow;

          svg {
            stroke: yellow;
          }
        }
      }
    }
    .rdt_TableBody {
      .expandable-content {
        background-color: orange;
        color: brown;
      }
      .rdt_TableRow {
        background-color: orange;
        color: brown;
        border-color: #eee;
        .rdt_TableCell {
          color: brown;
          .column-action {
            svg {
              stroke: brown;
            }
          }
        }
        button {
          &:hover:not(:disabled) {
            background-color: pink;
          }
          svg {
            color: brown;
          }
        }
        .permissions-actions {
          button:hover {
            background-color: transparent;
          }
        }
      }
    }
    .rdt_Pagination {
      color: brown;
      border-color: #eee;
      background-color: orange;

      svg {
        fill: brown;
      }
    }
  }

  .rdt_Pagination {
    color: brown;
    background-color: green;
    button {
      svg {
        fill: brown;
      }
      &[disabled] svg {
        fill: gray;
      }
    }
  }
}
.react-dataTable .rdt_TableHead .rdt_TableHeadRow{
  height: max-content !important;
}
.react-dataTable header{
  background-color: transparent;
  border: 1px solid #edebf2 ;
  border-bottom: 0px;
  padding-bottom: 10px;
}
.rdt_TableCol , .rdt_TableCell{
  border: 1px solid #edebf2 ;
  border-top: none;
  border-bottom: none;
  display: flex;
  justify-content: center;
  background-color: transparent;
  // padding: 8px 0px !important;
}
.rdt_TableCol_Sortable{
  font-size: 1rem;
}
.rdt_TableCol {
  border-left: 0px;
}
.rdt_TableCol:first-of-type{
  border-left: 1px solid #edebf2;
}
.rdt_TableCell{
  border-right: none ;
  border-bottom: none ;
}
.rdt_TableBody{
  border-bottom: 1px solid #edebf2 ;
  border-right: 1px solid #edebf2 ;
}

.refresh_table{
  color: white;
  border: 1px solid white;
  transition-duration: 0.6s;
  font-weight: bold;
  &:hover{
    color: #001c28;
    background-color: white;
  }
}

.custom_table_button_m17{
  &:first-of-type{
    align-items: flex-start !important;
  }
  &:last-of-type{
    align-items: flex-end !important;
  }
  @media (max-width:767px) {
    .refresh_table , .add-new-user{
      font-size: 0.6rem !important;
    }
  }
  @media (max-width:370px) {
    width: 100% !important;
    margin: 5px 0px ;
    &:first-of-type{
      align-items: center !important;
    }
    &:last-of-type{
      align-items: center !important;
    }
  }
}
.rdt_Table , .rdt_TableRow{
  background-color: transparent !important;
}
.customTableSearch{
  padding: 0px 5px !important;
  margin-top: 2px !important;
}

.rdt_TableRow:not(:last-of-type){
  border-bottom: 1px solid #edebf2  !important;
}