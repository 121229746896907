//
// switcher.scss
//
.switcher-btn {
    position: fixed;
    top: 200px;
    height: 41px;
    width: 40px;
    padding: 3px;
    border-radius: 0 30px 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.style-switcher {
    .t-dark, 
    .t-rtl-light {
        display: inline-block;
    }
    .t-ltr-light,
    .t-light,
    .t-rtl-dark,
    .t-ltr-dark {
        display: none;
    }
}
@media only screen and (max-width: 375px) {
	.switcher-btn {
		display: none !important;
    }
}

//LTR Continues in RTL Version
[dir="rtl"] {
    .style-switcher {
        .t-rtl-light {
            display: none;
        }
        .t-ltr-light {
            display: inline-block;
        }
    }
}